const config = useRuntimeConfig().public

export const formatCoin = (balance: number) => {
  const numberFormat = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(balance)
  return numberFormat
}

export const formatUsdToBigNumber = (value: number | string) => {
  if (Number(config.test_net)) {
    return Number(value) * 1000 * 1000 * 1000
  } else {
    return Number(value) * 1000 * 1000
  }
}

export const formatBigNumberToUsd = (value: number | string) => {
  if (Number(config.test_net)) {
    return Number(value) / (1000 * 1000 * 1000)
  } else {
    return Number(value) / (1000 * 1000)
  }
}

export const formatInteger = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const intToHex = (value: number) => {
  return '0x' + value.toString(16)
}

export const shortenText = (data: String, start = 6, end = 6) => {
  if (data.length <= start + end) {
    return data
  }
  return data ? data.substr(0, start) + '...' + data.substr(-end, end) : null
}

export function formatBalanceInput(val: string) {
  const valRaw = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',').split('.')
  if (!val) {
    return val
  }
  if (valRaw.length === 1) {
    return valRaw.at(0)
  }
  return [valRaw.at(0), valRaw.at(1)?.replaceAll(',', '')].join('.')
}